import { Layout } from 'components/Layout';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { AnimatedContainer } from 'src/components/AnimatedContainer';
import { Box } from 'src/components/Box';
import { Flex } from 'src/components/Flex';
import { IO } from 'src/components/IO';
import { LandingFeatureGrid } from 'src/components/Landing/LandingFeatureGrid';
import { MediaSection } from 'src/components/MediaSection';
import { MethodsLandingHero } from 'src/components/MethodsLandingHero';
import { ClientList } from 'src/components/OurClientsAlt/OurClientsAlt';
import { Section } from 'src/components/Section';

export default function manage({ data }) {
    return (
        <Layout
            pageTitleFull="Online community management by Audentio"
            siteDescription="Our team of dedicated community managers can help moderate and manage your digital community."
            contactForm
            methodSubNavIcon="/methods/manage.svg"
            methodSubNavColor="#EB4E4B"
            subnavTitle="Manage"
            subnav={[
                {
                    id: 'features',
                    label: 'Features',
                },
                {
                    id: 'community-managers',
                    label: 'Community Managers',
                },
            ]}
            wrapChildren
        >
            <MethodsLandingHero
                title="Hands-free online community management"
                video="/videos/methods/manage/hero.mp4"
                videoPoster="/videos/methods/manage/hero-poster.png"
                videoId="methods-manage-hero"
                subtitle="Our team of community managers are there for you to help moderate, monitor, and manage your community so you have the freedom to engage how you want."
            />

            <LandingFeatureGrid
                id="features"
                title="Daily dedicated resource to help guide, grow, and engage with your community."
                items={[
                    {
                        heading: 'run events and contests',
                        content:
                            'A valuable and fun way for your members to engage with your products or services.',
                    },
                    {
                        heading: 'welcome and guide new members',
                        content:
                            'Help members feel like they belong and set them up for success as quickly as possible.',
                    },
                    {
                        heading: 'moderation and conflict resolution',
                        content:
                            'Properly addressing issues via forum moderation can avoid larger fallouts and ultimately help with the culture.',
                    },
                    {
                        heading: 'ease transition after an upgrade',
                        content:
                            'Along with being active in feedback threads, we create guides and videos on new features.',
                    },
                    {
                        heading: 'address bugs and suggestions',
                        content:
                            'Documenting bug reports, routine fixes, and frequent communication with the community.',
                    },
                    {
                        heading: 'improve community culture',
                        content:
                            'Routine check-ins and quarterly webinars can help show members that their voice matters.',
                    },
                ]}
                mb="0"
            />

            <Section
                id="community-managers"
                vertical="lg"
                pb={[0, 0, 0]}
                wrap
                bg="secondaryBackground"
            >
                <ClientList
                    title="Valued Partners"
                    clients={[
                        { name: 'IGN', image: data.ign },
                        { name: 'cPanel', image: data.cpanel },
                        { name: 'Yahoo!', image: data.yahoo },
                        { name: 'MacRumors', image: data.macrumors },
                        { name: "Tom's hardware", image: data.tomshardware },
                    ]}
                    borderBottom="1px"
                    borderColor="border"
                    pb={['spacing-lg', '40px', 'spacing-xl']}
                />

                <MediaSection
                    id="manage"
                    title="On-hand managers look after your community on a regular basis"
                    customMedia={
                        <IO rootMargin="-25%">
                            {({ isVisible }) => (
                                <Flex
                                    justify={{
                                        _: null,
                                        lg: 'center',
                                    }}
                                    h={[300, null, null, 400]}
                                    // bottom margin so that offset image animation aligns with section padding after animation, rather than before
                                    mb={{
                                        _: 0,
                                        lg: 30,
                                    }}
                                >
                                    <Box
                                        w={[300, null, null, 400]}
                                        bg="black"
                                        pos="relative"
                                        h="100%"
                                    >
                                        <AnimatedContainer
                                            isAnimated={isVisible}
                                            offset={{
                                                initial: {
                                                    y: 0,
                                                    x: 0,
                                                },
                                                final: {
                                                    y: 30,
                                                    x: 30,
                                                },
                                            }}
                                        >
                                            <Img
                                                style={{
                                                    width: '105%',
                                                    height: '105%',
                                                }}
                                                fluid={
                                                    data.managerphotogrid
                                                        .childImageSharp.fluid
                                                }
                                                alt="Profile photos of community management team."
                                            />
                                        </AnimatedContainer>
                                    </Box>
                                </Flex>
                            )}
                        </IO>
                    }
                >
                    To build good culture — the kind that makes people happy to
                    be part of a community — is a continuous process that relies
                    on methods our community managers can handle to ensure your
                    members feel seen, heard, and recognized.
                </MediaSection>
            </Section>
        </Layout>
    );
}

export const query = graphql`
    query manageQuery {
        managerphotogrid: file(
            relativePath: { eq: "images/manage/manager-photo-grid.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        snipershide: file(
            relativePath: { eq: "images/clients/snipershide.png" }
        ) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        ign: file(relativePath: { eq: "images/clients/ign.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        tomshardware: file(
            relativePath: { eq: "images/clients/toms-hardware.png" }
        ) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        macrumors: file(relativePath: { eq: "images/clients/macrumors.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        yahoo: file(relativePath: { eq: "images/clients/yahoo-sports.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        cpanel: file(relativePath: { eq: "images/clients/cpanel.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
